import {
    REMAINING,
    PLAN_SERVICE_LIST,
    HEALTH_MANAGER_LIST,
    SERVICE_PLAN_DAY_LIST,
    SERVICE_PLAN_OPERATE,
    SERVICE_PLAN_LIST,
    SERVICE_PLAN_INFO,
    TIME_LIST_TIME,
    LAST_INFO,
    LICHUANG_REPORT,
    YINGYAN_REPORT,
    SEF_REPORT,
    LICHUANG_DATE,
    change,
    changeTwo,
    MODIFY,
    JWT_AUTH,
    THIR_PARTY,
    THIR_PARTY_COUNT,
    CONTRO_LIST,
    MAIQUAN_REPORT,
    ZHIBIAO_REPORT,
    ZHIBIAO_REPORT_CREATE,
    WENQUAN_REPORT,
    WENQUAN_REPORT_EDIT,
    FANGAN_REPORT,
    FANGAN_REPORT_EDIT,
    ZHONGYI_TIAOYANG_LIST,
    GONGNENG_GANYU_LIST,
    MODIFY_All,
    YINGYANG_LIST,
    SHUIKE,
    SHUIKE_LIST,
    SHUIKE_ROOM,
    SHUIKE_REPORT
} from './actionTypes'
import { message } from 'antd';
import { yibiao , shuiqian , Kline , pause , area , waterfall } from '../../utils/chart';
const echarts = require('echarts/lib/echarts');
//折
require('echarts/lib/chart/gauge');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');


// 数据池
const defaultState = {
    service:[],
    shuike:[],
    plan_info:[],
    time_list:[],
    shuike_list:[],
    service_list:[],
    service:null,
    service_s:null,
    plan_list:[],
    health_list:[],
    day_list:[],
    last_info:{},
    service_loading:false,
    lcReport:null,
    yangyan:[],
    quanmai:null,
    lichuang_date:[],
    lichuang:'',
    module:{
        renovate:true,
        data:[]
    },
    doc:'',
    user:null,
    thir:[],
    count:null,
    controlist:[],
    maiquan:[],
    zhibiao:null,
    zhibiaoJson:null,
    wenjuan:null,
    wenjuan_list:null,
    fangan:null,
    ganyu:null,
    tiaoyang:null,
    yingyang:null,
    shuike:[],
    shuikeData:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case REMAINING.GET_REMAINING:
            let data = action.data;
            if(data.service && data.service.length>0){
                let timeService = [];
                data.service.map((service,index1)=>{
                    data.time.map((time,index)=>{
                        timeService.push({
                            id:`${index1}-${index}`,
                            service_id:service.id,
                            service_name:index==0?`${service.service_name}(${service.curren_day_count})`:'',
                            time:time,
                            plan_time:data.plan_time[service.id] && data.plan_time[service.id][time.id] ? data.plan_time[service.id][time.id]:0,
                            active:false
                        })
                    })
                });
                newState.service_list = timeService;
                newState.service_s = data;
            }
            return newState;
        case SERVICE_PLAN_LIST.GET_SERVICE_PLAN_LIST:
            newState.service_loading = false;
            newState.service = action.data;
            return newState;
        case SERVICE_PLAN_LIST.SERVICE_PLAN_LIST:
            newState.service_loading = true;
            newState.service = [];
            return newState;
        case SERVICE_PLAN_INFO.GET_SERVICE_PLAN_INFO:
            newState.plan_info = action.data;
            return newState;
        case TIME_LIST_TIME.GET_TIME_LIST_TIME:
            newState.time_list = action.data;
            return newState;
        case SERVICE_PLAN_OPERATE.GET_SERVICE_PLAN_OPERATE:
            message.success('操作成功！');
            setTimeout(function(){
                window.history.go(0);
            },1000);
            return newState;
        case PLAN_SERVICE_LIST.GET_PLAN_SERVICE_LIST:
            newState.plan_list = action.data;
            return newState;
        case HEALTH_MANAGER_LIST.GET_HEALTH_MANAGER_LIST:
            newState.health_list = action.data;
            return newState;
        case SERVICE_PLAN_DAY_LIST.POST_SERVICE_PLAN_DAY_LIST:
            newState.day_list = action.data;
            return newState;
        case LAST_INFO.GET_LAST_INFO:
            newState.last_info = action.data;
            return newState;
        case LICHUANG_DATE.GET_LICHUANG_DATE:
            let lichuang = [];
            if(action.data.length>0){
                action.data.map((lic)=>{
                    lichuang.push({ value: lic, label: lic })
                });
                newState.lichuang = action.data[0];
            }
            newState.lichuang_date = lichuang;
            return newState;
        case LICHUANG_DATE.NO_LICHUANG_DATE:
            newState.lcReport = null;
            newState.yangyan = [];
            newState.lichuang_date = [];
            newState.quanmai=null;
            newState.lichuang = '';
            newState.module = {
                renovate:true,
                data:[]
            };
            newState.doc = '';
            return newState;
        case YINGYAN_REPORT.GET_YINGYAN_REPORT:
            if(action.data.reportInfo){
                let reportInfo = JSON.parse(action.data.reportInfo);
                let loksOjb = [];
                yy_loks.map((loks)=>{
                    if(reportInfo[loks]){
                        loksOjb.push({
                            measureMentName:loks,
                            measureContent:reportInfo[loks]
                        })
                    }
                });
                newState.doc = reportInfo.doc;
                newState.yangyan = loksOjb;
            }else{
                newState.doc = '';
                newState.yangyan = [];
            }
            return newState;
        case SEF_REPORT.GET_SEF_REPORT:
            if(action.data.reportInfo){
                let info = JSON.parse(action.data.reportInfo).data;
                console.log(info);
                //洗数据
                let big = [] , little = [] ,bigData = [],littleData=[];
                for( let item in info.syndrome_danger ){
                    let hot = 0 , syndrome_danger = info.syndrome_danger;
                    for(let i=0,n=syndrome_danger[item].list.length;i<n;i++){
                        if(syndrome_danger[item].list[i].risk_index){
                            hot++;
                            little.push(syndrome_danger[item].list[i].disease_name);
                            info.syndrome_danger[item].list[i].key = syndrome_danger[item].name;
                            littleData.push(syndrome_danger[item].list[i]);
                        }
                    }
                    if(hot>0){
                        big.push(syndrome_danger[item].name);
                        bigData.push(syndrome_danger[item]);
                    }
                }
                info.big = big;
                info.little = little;
                info.littleData = littleData;
                newState.quanmai = info;

                let module_list = info.module_list;
                //模块
                if(module_list){
                    for(let i=0,n=module_list.length;i<n;i++){
                        let description_list = info.module_list[i].description_list;
                        let hoo = 0;
                        for(let description in description_list){
                            if(hoo == 0){
                                module_list[i].description_list[description].active = true;
                            }
                            if(module_list[i].description_list[description].list){
                                module_list[i].description_list[description].list[0].active = true;
                            }   
                            hoo++;
                        }
                    }
                }

                newState.module = {
                    renovate:!state.module.renovate,
                    data:module_list?module_list:[]
                }

                setTimeout(function(){
                    Meter('diag-target-1',info.score);
                },1)
            }else{
                newState.module = {
                    renovate:!state.module.renovate,
                    data:[]
                }
                newState.quanmai = null;
            }
            return newState;
        case LICHUANG_REPORT.GET_LICHUANG_REPORT:
            let measureMent = JSON.parse(action.data.measureMent);
            measureMent.map((ment,index)=>{
                ment.measureContent.map((item,index1)=>{
                    lc_Josn.map((json)=>{
                        if(json.name==item.measureDesc){
                            if(!item.measureRange){
                                measureMent[index].measureContent[index1].measureRange = json.measureRange
                            }
                        }
                    })
                })
            });
            action.data.measureMent = measureMent;
            newState.lcReport = action.data;
            return newState;
        case change.change:
            newState.module = changeFun(state,action.params);
            return newState;
        case changeTwo.changeTwo:
            newState.module = changeTwoFun(state,action.params);
            return newState;
        case JWT_AUTH.POST_JWT_AUTH:
            newState.user = action.data;
            return newState;
        case THIR_PARTY.GET_THIR_PARTY:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_MAIQUAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_LICHUANG:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_SELF:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_YINGYAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_ZHIBIAO:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_SHUIKE:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_WENQUAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_FANGAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY_COUNT.GET_THIR_PARTY_COUNT:
            newState.count = action.data;
            return newState;
        case MAIQUAN_REPORT.GET_MAIQUAN_REPORT:
            newState.maiquan = action.data;
            return newState;
        case MODIFY:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        case MODIFY_All:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        case CONTRO_LIST.POST_CONTRO_LIST:
            newState.controlist = action.data;
            return newState;
        case ZHIBIAO_REPORT.GET_ZHIBIAO_REPORT:
            newState.zhibiao = JSON.parse(action.data);
            newState.zhibiao.syndrome_desc = newState.zhibiao.syndrome_desc?JSON.parse(newState.zhibiao.syndrome_desc):"";
            newState.zhibiaoJson = action.data;
            return newState;
        case SHUIKE_REPORT.GET_SHUIKE_REPORT:
            if(action.data){
            if(action.data.report.health_hr){
                action.data.report.health_hr = JSON.parse(action.data.report.health_hr);
            }
            if(action.data.report.health_hrv){
                action.data.report.health_hrv = JSON.parse(action.data.report.health_hrv);
            }
            if(action.data.report.health_rr){
                action.data.report.health_rr = JSON.parse(action.data.report.health_rr);
            }
            if(action.data.report.sleep_duration){
                action.data.report.sleep_duration = JSON.parse(action.data.report.sleep_duration);
            }
            if(action.data.report.sleep_restlessness){
                action.data.report.sleep_restlessness = JSON.parse(action.data.report.sleep_restlessness);
            }
            if(action.data.report.sleep_stages){
                action.data.report.sleep_stages = JSON.parse(action.data.report.sleep_stages);
            }
            if(action.data.report.sleep_routine){
                action.data.report.sleep_routine = JSON.parse(action.data.report.sleep_routine);
            }

            if(action.data.report['breathing-interruption']){
                action.data.report['breathing-interruption'] = JSON.parse(action.data.report['breathing-interruption']);
            }

            if(action.data.report['sleep-score']){
                action.data.report['sleep-score'] = JSON.parse(action.data.report['sleep-score']);
            }
            
            if(action.data.report['health-score']){
                action.data.report['health-score'] = JSON.parse(action.data.report['health-score']);
            }
            
            newState.shuikeData = action.data;
            
            // if(newState.shuikeData.report.sleep_duration){
            //     setTimeout(function(){
            //         yibiao('diagram-1',{
            //             max:9,
            //             min:0,
            //             data:newState.shuikeData.report.sleep_duration.sleepTime,
            //             value:newState.shuikeData.report.sleep_duration.sleepDurationStr
            //         });
            //     },1)
            // }

            if(newState.shuikeData.report.sleep_stages){
                let filterData = filterateFun(newState.shuikeData.report.sleep_stages.list);
                setTimeout(function(){
                    shuiqian('diagram-2',{
                        xAxis:filterData.x,
                        data:filterData.y
                    });
                },1)
            }

            if(newState.shuikeData.report['health_hr']){
                let health_hr = newState.shuikeData.report['health_hr'];
                let list = health_hr.list;
                let x = [];
                let y = [];
                if(list.length>0){
                    list.map((lir)=>{
                        let start = lir.start;
                        x.push(formatSeconds(start));
                        lir.list.map((sit,index)=>{
                            if(index){
                                start = start+60;
                                x.push(formatSeconds(start));
                            }
                        })
                        y = y.concat(lir.list);
                    })
                }
                setTimeout(function(){
                    Kline('diagram-3',{
                        data:x,
                        value:y
                    })
                },1)
            }
            if(newState.shuikeData.report['health_rr']){
                let health_rr = newState.shuikeData.report['health_rr'];
                let list = health_rr.list;
                let x = [];
                let y = [];
                if(list.length>0){
                    list.map((lir)=>{
                        let start = lir.start;
                        x.push(formatSeconds(start));
                        lir.list.map((sit,index)=>{
                            if(index){
                                start = start+60;
                                x.push(formatSeconds(start));
                            }
                        })
                        y = y.concat(lir.list);
                    })
                }
                setTimeout(function(){
                    Kline('diagram-7',{
                        data:x,
                        value:y
                    })
                },1)
            }
            if(newState.shuikeData.report['breathing-interruption']){
                let breathing_interruption = newState.shuikeData.report['breathing-interruption'];
                let min = breathing_interruption.start;
                let max = breathing_interruption.end;
                let list = breathing_interruption.list;
                let x = [];
                if(list.length>0){
                    let diff= parseInt(((max+86400)-min)/list.length);
                    x.push(formatSeconds(min));
                    let f = min;
                    list.map((lir,index)=>{
                        if(index){
                            x.push(formatSeconds(f+diff));
                            f= f+diff;
                        }
                    })
                }
                
                setTimeout(function(){
                    pause('diagram-4',{
                        data:x,
                        value:list
                    });
                },1)
            }

            if(newState.shuikeData.report['health_hrv']){
                let health_hrv = newState.shuikeData.report['health_hrv'];
                let timeList = health_hrv.timeList;
                let list = health_hrv.list;
                let x = [];
                //去除时间区间的最大值和最小值，根据list的个数算出时间
                if(list.length>0){
                    timeList.map((item,index)=>{
                        if(item<43200){
                            timeList[index] = item+86400;
                        }
                    })
                    let max = Math.max(...timeList);
                    let min = Math.min.apply(null,timeList);
                    //等分时间戳
                    let diff= parseInt((max-min)/list.length);
                    x.push(formatSeconds(min));
                    let f = min;
                    list.map((lir,index)=>{
                        if(index){
                            x.push(formatSeconds(f+diff));
                            f= f+diff;
                        }
                    })
                }
                setTimeout(function(){
                    area('diagram-5',{
                        data:x,
                        value:list
                    });
                },1)
            }
            if(newState.shuikeData.report.sleep_restlessness){
                let list = filterateFun(newState.shuikeData.report.sleep_restlessness.list).data;
                let x = [];
                let x1 = [];
                let y = [];
                //删除最开始和最后两个觉醒数据
                if(list.length>0){
                    list[0].type=0;
                    list[list.length-1].type=0;
                    list.map((lir,index)=>{
                        if(lir.type==4||lir.type==5){
                            x.push(1);
                            x1.push(0);
                        }else if( lir.type==0 && index!==0 && index!==list.length-1 ){
                            x.push(0);
                            x1.push(1);
                        }else{
                            x.push(0);
                            x1.push(0);
                        }
                        y.push(lir.start)
                    })
                }
                setTimeout(function(){
                    pause('diagram-6',{
                        data:y,
                        value:x,
                        value1:x1
                    });
                },1)
            }

            // if(newState.shuikeData.report.sleep_routine){
            //     let list = newState.shuikeData.report.sleep_routine.list;
            //     let x = [];
            //     let z = [];
            //     let i = [];
            //     let y = [];
            //     let u = [];

                

            //     list.map((lir,index)=>{
            //         x.push(lir.pointXFlag);
            //         z.push(miaoDate(guilvDate(lir.timeOutOfBed)));
            //         i.push(miaoDate(guilvDate(lir.timeOutOfBed),guilvDate(lir.wakeUpTime),true));
            //         y.push(miaoDate(guilvDate(lir.wakeUpTime),guilvDate(lir.timeToSleep),true));
            //         u.push(miaoDate(guilvDate(lir.timeToSleep),guilvDate(lir.timeToBed),true));
            //     })

            //     setTimeout(function(){
            //         waterfall('diagram-12',{
            //             data:x,     //周几
            //             qic:z,      //起床时间，空
            //             juex: i,    //起床时间 - 觉醒时间,
            //             shuim:y,    //觉醒时间 - 入睡时间
            //             shangc:u    //入睡时间 - 上床时间
            //         });
            //     },1)
            // }
            }else{
                newState.shuikeData = null;
            }

            return newState;
        case WENQUAN_REPORT.GET_WENQUAN_REPORT:
            if(action.date){
                newState.wenquan = action.data;
                newState.wenquan.reportInfo = newState.wenquan.reportInfo?JSON.parse(newState.wenquan.reportInfo):"";
                if(action.fun){
                    action.fun(action.data.reportInfo);
                }
            }else{
                newState.wenjuan_list = action.data;
            }
            return newState;
        case FANGAN_REPORT.GET_FANGAN_REPORT:
            newState.fangan = action.data;
            newState.fangan.answer = newState.fangan.answer?JSON.parse(newState.fangan.answer):"";
            newState.fangan.question = newState.fangan.question?JSON.parse(newState.fangan.question):"";
            if(action.fun){
                action.fun(newState.fangan.answer);
            }
            return newState;
        case ZHONGYI_TIAOYANG_LIST.GET_ZHONGYI_TIAOYANG_LIST:
            newState.tiaoyang = action.data;
            return newState;
        case GONGNENG_GANYU_LIST.GET_GONGNENG_GANYU_LIST:
            newState.ganyu = action.data;
            return newState;
        case SHUIKE.GET_SHUIKE:
            newState.shuike = action.data;
            return newState;
        case SHUIKE_LIST.GET_SHUIKE_LIST:
            newState.shuike_list = action.data;
            return newState;
        case YINGYANG_LIST.GET_YINGYANG_LIST:
            newState.yingyang = action.data;
            return newState;
        default:
            return state;
    }
};

//一级目录
function changeFun(state,params){
    let module = state.module.data;
    if(module[params.index].description_list){
        let i = 0;
        for(let item in module[params.index].description_list){
            module[params.index].description_list[item].active = false;
            if(i==params.index1){
                module[params.index].description_list[item].active = true;
            }
            i++;
        }        
    }
    return {
        renovate:!state.module.renovate,
        data:module
    }
}

//二级目录
function changeTwoFun(state,params){
    let module = state.module.data;
    if(module[params.index].description_list){
        for(let item in module[params.index].description_list){
            if(module[params.index].description_list){
                let i = 0;
                for(let item1 in module[params.index].description_list[item].list){
                    module[params.index].description_list[item].list[item1].active=false;
                    if(i==params.index2){
                        module[params.index].description_list[item].list[params.index2].active=true
                    }
                    i++;
                }
            }
        }        
    }
    return {
        renovate:!state.module.renovate,
        data:module
    }
}


export default Reducer;

let lc_Josn = [
{
    name:'体重',
    measureRange:'40-150'
},
{
    name:'BMI',
    measureRange:'18.5-23.9'
},
{
    name:'收缩压',
    measureRange:'90-139'
},
{
    name:'舒张压',
    measureRange:'60-89'
},
{
    name:'脉搏',
    measureRange:'60-100'
},
{
    name:'血氧饱和度',
    measureRange:'95-100'
},
{
    name:'脉率',
    measureRange:'60-100'
},
{
    name:'体脂肪率',
    measureRange:'11-21'
},
{
    name:'血糖',
    measureRange:'3.9-11.1'
},
{
    name:'尿酸',
    measureRange:'90-300'
},
{
    name:'胆固醇',
    measureRange:'2.9-6.0'
}
];


let yy_loks = ['人体成分和随访','健康 评分','内皮评分','对主要指标的跟踪能够让医生核对他们的方案变化或监测治疗或生活方式的改变。','汗腺功能/电导分析 ','脊椎评分',''];

//仪表盘
function Meter(dom,score){
    var chartDom = document.getElementById(dom);
    echarts.dispose(chartDom);
    var myChart = echarts.init(chartDom);
    var option;
    option = {
        series: [{
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 0,
            axisLine: {
                lineStyle: {
                    width: 6,
                    color: [
                        [0.5, '#F36440'],
                        [0.65, '#F89143'],
                        [0.8, '#ECDB44'],
                        [0.9, '#5CC7BC'],
                        [1, '#83D186']
                    ]
                }
            },
            splitLine: {
                length: 20,
                lineStyle: {
                    color: 'auto',
                    width: 5
                }
            },
            axisLabel: {
                color: '#464646',
                fontSize: 15,
                distance: -60,
                formatter: function (value) {
                    return value?value:''
                }
            },
            detail: {
                fontSize: 16,
                offsetCenter: [0, '40%'],
                valueAnimation: false,
                formatter: function (value) {
                    let score = Math.round(value);
                    let score_text = '';
                    if(0<score && score<=50){
                        score_text = '可能患有疾病，建议就医检查';
                    }
                    if(50<score && score<=65 ){
                        score_text= "身体极不平衡，建议马上调理";
                    }
                    if(65<score && score<=80 ){
                        score_text= "身体较不平衡，建议马上调理";
                    }
                    if(80<score && score<=90 ){
                        score_text= "身体较为平衡，建议适当调理";
                    }
                    if(90<score && score<=100 ){
                        score_text= "身体较为平衡，建议适当调理";
                    }
                    return score_text;
                },
                color: 'auto'
            },
            data: [{
                value: score
            }]
        }]
    };
    option && myChart.setOption(option);
}

function filterateFun(lists){
    let x = [];
    let y = [];
    lists.map((list)=>{
        list.start = formatSeconds(list.start);
        list.end = formatSeconds(list.end);
        x.push(list.end);
        let y_reverse = '';
        switch(list.type){
            case 0:
                y_reverse = 0;
                break;
            case 1:
                y_reverse = 4;
                break;
            case 2:
                y_reverse = 2;
                break;
            case 3:
                y_reverse = 3;
                break;
            case 4:
                y_reverse = 1;
                break;
        }
        y.push(y_reverse)
    })
    return {
        x:x,
        y:y,
        data:lists
    };
}

function formatSeconds(value){
    let second = parseInt(value)
    let minute = 0
    let hour = 0
    if (second > 60) {
      minute = parseInt(second / 60)
      second = parseInt(second % 60)
      if (minute > 60) {
        hour = parseInt(minute / 60)
        minute = parseInt(minute % 60)
      }
    }
  
    let result = '';
    if (minute >= 0) {
      result = '' + max10(parseInt(minute) + result)
    }
    if (hour >= 0) {
      result = '' + max10(parseInt(hour>=24?hour-24:hour)) + ':' + result
    }

    return result
}

//计算时间
function guilvDate (date0){
    let result = '12:00:00'
    if(date0){
        result = `${max10(parseInt(date0/60))}:${max10(parseInt(date0%60))}:00`
    }
    return result;
}

//计算 两个时间的秒数
function miaoDate(date1,date2='12:00:00',bool){
    let miao = 0;
    
    date1 = Date.parse(buqiDate(date1).replace(/-/g,"/"));
    date2 = Date.parse(buqiDate(date2).replace(/-/g,"/"));

    miao = (date1 - date2)/1000;
    if(bool){
        return miao;
    }else{
        return miao?(86400-miao):0;
    }
}

//补齐时间
function buqiDate(date3){
    let newh = date3.split(':')[0];
    if(newh>=12){
        return `2023-01-01 ${date3}`;
    }else{
        return `2023-01-02 ${date3}`;
    }
}

function max10(date5){
    return date5>=10?date5:`0${date5}`;
}